// Company Type
export const COMPANY_TYPE_BUYER = 2;
export const COMPANY_TYPE_DEBTOR = 4;
export const COMPANY_TYPE_ISO = 3;
export const COMPANY_TYPE_SELLER = 1;
export const COMPANY_TYPE_CONFIRMING = 5;

// Company UBO Status
export const COMPANY_UBO_STATUS_NEW = 0;
export const COMPANY_UBO_STATUS_DRAFT = 1;
export const COMPANY_UBO_STATUS_SUBMITTED = 2;
export const COMPANY_UBO_STATUS_APPROVED = 3;
export const COMPANY_UBO_STATUS_EXPIRESOON = 4;
export const COMPANY_UBO_STATUS_EXPIRED = 5;
export const COMPANY_UBO_STATUS_REJECTED = 6;

// Invoice state filter
export const INVOICE_STATE_FILTER_OPEN = 'open';
export const INVOICE_STATE_FILTER_CLOSED = 'closed';

// Invoice date filter
export const INVOICE_DATE_FILTER_PURCHASED = 'purchasedDate';
export const INVOICE_DATE_FILTER_CLOSED = 'closedDate';

// Invoice status
export const INVOICE_STATUS_PURCHASED = 2059;
export const INVOICE_STATUS_PURCHASED_MANDATED = 3686;
export const INVOICE_STATUS_TRANSFERRED = 3335;

// Market Place
export const MARKETPLACE_INSURED = 3838;
export const MARKETPLACE_NOT_INSURED = 3839;
export const MARKETPLACE_DELEGATED_INSURED = 3840;
export const MARKETPLACE_PUBLIC = 3841;
export const MARKETPLACE_EDOF = 3894;
export const MARKETPLACE_CONFIRMING = 4141;

// Document Type Filter
export const DOC_TYPE_ID = 86;
export const DOC_TYPE_ID_FRONT = 171;
export const DOC_TYPE_ID_BACK = 172;
export const DOC_TYPE_PASSPORT = 10203;
export const DOC_TYPE_RESIDENT_FRONT = 10204;
export const DOC_TYPE_RESIDENT_BACK = 10205;
export const DOC_TYPE_KBIS = 92;
export const DOC_TYPE_RIB = 93;
export const DOC_CODE_RIB = "RIB";
export const DOC_TYPE_BKA = 223;
export const DOC_CODE_BKA = "BKA";
export const DOC_TYPE_PROCURATION = 91;
export const DOC_CODE_PROCURATION = "PRC";

// Registration types
export const REGISTRATTION_TYPE_PASSPORT = 1928;
export const REGISTRATTION_TYPE_RESIDENT = 1929;
export const REGISTRATTION_TYPE_CITIZEN = 1930;

// Document Entity Type Filter
export const DOC_ENTITY_TYPE_PERSON = 3296;

// Entity Typy Filter
export const ENTITY_COMPANY = 3294;
export const ENTITY_USER = 3296;

// ISO Roles
export const ISO_ROLE_ADMINISTRATOR = 3721;
export const ISO_ROLE_ACCOUNT_MANAGER = 3722;
export const ISO_ROLE_OFFICE_MANAGER = 3723;
export const ISO_ROLE_NETWORK_MANAGER = 3724;
export const ISO_ROLE_ACCOUNTANT = 3883;

// Debtor Roles
export const DEBTOR_ROLE_ADMINISTRATOR = 3725;
export const DEBTOR_ROLE_APPROVER = 3726;
export const DEBTOR_ROLE_OPERATIONS = 3728;
export const DEBTOR_ROLE_COMMERCIAL = 3888;
export const DEBTOR_ROLE_ACCOUNTANT = 3727;

// Buyer Roles
export const BUYER_ROLE_ADMINISTRATOR = 3874;
export const BUYER_ROLE_CONSULTANT = 3886;
export const BUYER_ROLE_BASIC = 3887;

// Seller Roles
export const SELLER_ROLE_ADMINISTRATOR = 3875;
export const SELLER_ROLE_CONSULTANT = 3884;
export const SELLER_ROLE_BASIC = 3885;

// Confirming Roles
export const CONFIRMING_ROLE_ADMINISTRATOR = 4144;
export const CONFIRMING_ROLE_APPROVER = 4145;
export const CONFIRMING_ROLE_ACCOUNTANT = 4146;
export const CONFIRMING_ROLE_COMMERCIAL = 4148;


// Unused Languages
export const USER_LANGUAGE_ITALIAN = 1969;
export const USER_LANGUAGE_GERMAN = 158;