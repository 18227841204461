/* @flow */

import React from 'react';
import { useSelector } from 'react-redux';

import Box from '@material-ui/core/Box';

import BlogComponent from 'components/blogs';
import SecurityComponent from 'components/security';
import ConfirmingKPIsComponent from './kpis';
import ConfirmingInvoicesToSignTableWithTitleComponent from './invoicesToApprove';
import ConfirmingProfileSummaryComponent from './profileSummary';
import ConfirmingOpenInvoicesTableComponent from './openInvoices';

export default function ConfirmingDashboardComponent() {
  const languageId = useSelector(state => state.locale.languageId);

  return (
    <React.Fragment>
      <ConfirmingProfileSummaryComponent />
      <Box mt={3}>
        <SecurityComponent pageName={"POS.Confirming.Dashboard"} elementName={"tblInvoiceApproval"} checkRoleExecute={true}>
          <ConfirmingInvoicesToSignTableWithTitleComponent />
        </SecurityComponent>
      </Box>
      <Box mt={3}>
        <SecurityComponent pageName={"POS.MainMenu"} elementName={"mnuMyPortfolio"}>
          <ConfirmingOpenInvoicesTableComponent />
        </SecurityComponent>
      </Box>
      <Box mt={3}>
        <ConfirmingKPIsComponent />
      </Box>
      <Box mt={5}>
        <BlogComponent key={`blogs-${languageId}`} />
      </Box>
    </React.Fragment>
  );
}
